import React from 'react'
import { Helmet } from 'react-helmet'

import { Disclaimer as DisclaimerLayout } from 'container'

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimer</title>
      </Helmet>
      <DisclaimerLayout title="Disclaimer">
        <p>Updated at 2021-01-02</p>

        <p>
          viktorsdk hereby grants you access to https://viktorsdk.com (“the
          Website”) and invites you to purchase the services offered here.
        </p>

        <h4>Definitions and key terms</h4>
        <p>
          To help explain things as clearly as possible in this Disclaimer,
          every time any of these terms are referenced, are strictly defined as:
          <ul>
            <li>
              Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>
              Company: when this policy mentions “Company,” “we,” “us,” or
              “our,” it refers to viktorsdk, that is responsible for your
              information under this Disclaimer.
            </li>
            <li>
              Service: refers to the service provided by viktorsdk as described
              in the relative terms (if available) and on this platform.
            </li>
            <li>
              Website: site, which can be accessed via this URL:
              https://viktorsdk.com
            </li>
            <li>
              You: a person or entity that is registered with viktorsdk to use
              the Services.
            </li>
          </ul>
        </p>

        <h4>Limited liability</h4>
        <p>
          viktorsdk endeavours to update and/or supplement the content of the
          website on a regular basis. Despite our care and attention, content
          may be incomplete and/or incorrect.
        </p>
        <p>
          The materials offered on the website are offered without any form of
          guarantee or claim to their correctness. These materials can be
          changed at any time without prior notice from viktorsdk.
        </p>
        <p>
          Particularly, all prices on the website are stated subject to typing
          and programming errors. No liability is assumed for the implications
          of such errors. No agreement is concluded on the basis of such errors.
        </p>
        <p>
          viktorsdk shall not bear any liability for hyperlinks to websites or
          services of third parties included on the website. From our website,
          you can visit other websites by following hyperlinks to such external
          sites. While we strive to provide only quality links to useful and
          ethical websites, we have no control over the content and nature of
          these sites. These links to other websites do not imply a
          recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone ‘bad’.
        </p>
        <p>
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their "Terms of Service" before engaging in any business or
          uploading any information.
        </p>
        <h4>Links to Other Websites Disclaimer</h4>
        <p>
          This Disclaimer applies only to the Services. The Services may contain
          links to other websites not operated or controlled by viktorsdk. We
          are not responsible for the content, accuracy or opinions expressed in
          such websites, and such websites are not investigated, monitored or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Privacy
          Policy is no longer in effect. Your browsing and interaction on any
          other website, including those that have a link on our platform, is
          subject to that website’s own rules and policies. Such third parties
          may use their own cookies or other methods to collect information
          about you. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy and Terms of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h4>Errors and Omissions Disclaimer</h4>
        <p>
          viktorsdk is not responsible for any content, code or any other
          imprecision.
        </p>
        <p>viktorsdk does not provide warranties or guarantees.</p>
        <p>
          In no event shall viktorsdk be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. viktorsdk reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </p>

        <h4>General Disclaimer</h4>
        <p>
          The viktorsdk Service and its contents are provided "as is" and "as
          available" without any warranty or representations of any kind,
          whether express or implied. viktorsdk is a distributor and not a
          publisher of the content supplied by third parties; as such, viktorsdk
          exercises no editorial control over such content and makes no warranty
          or representation as to the accuracy, reliability or currency of any
          information, content, service or merchandise provided through or
          accessible via the viktorsdk Service. Without limiting the foregoing,
          viktorsdk specifically disclaims all warranties and representations in
          any content transmitted on or in connection with the viktorsdk Service
          or on sites that may appear as links on the viktorsdk Service, or in
          the products provided as a part of, or otherwise in connection with,
          the viktorsdk Service, including without limitation any warranties of
          merchantability, fitness for a particular purpose or non-infringement
          of third party rights. No oral advice or written information given by
          viktorsdk or any of its affiliates, employees, officers, directors,
          agents, or the like will create a warranty. Price and availability
          information is subject to change without notice. Without limiting the
          foregoing, viktorsdk does not warrant that the viktorsdk Service will
          be uninterrupted, uncorrupted, timely, or error-free.
        </p>
        <h4>Copyright Disclaimer</h4>
        <p>
          All intellectual property rights concerning these materials are vested
          in viktorsdk. Copying, distribution and any other use of these
          materials is not permitted without the written permission of
          viktorsdk, except and only to the extent otherwise provided in
          regulations of mandatory law (such as the right to quote), unless
          otherwise stated for certain materials.
        </p>
        <h4>Educational Disclosure</h4>
        <p>
          Any Information provided by viktorsdk is for educational purposes
          only, and is not to be interpreted as a recommendation for a specific
          treatment plan, product, or course of action. viktorsdk is a
          distributor and not a publisher of the content supplied by third
          parties; as such, viktorsdk exercises no editorial control over such
          content and makes no warranty or representation as to the accuracy,
          reliability or currency of any information or educational content
          provided through or accessible via viktorsdk. Without limiting the
          foregoing, viktorsdk specifically disclaims all warranties and
          representations in any content transmitted on or in connection with
          viktorsdk or on sites that may appear as links on viktorsdk, or in the
          products provided as a part of, or otherwise in connection with, the
          viktorsdk. No oral advice or written information given by viktorsdk or
          any of its affiliates, employees, officers, directors, agents, or the
          like will create a warranty.
        </p>
        <h4>Your Consent</h4>
        <p>
          We've updated our Disclaimer to provide you with complete transparency
          into what is being set when you visit our site and how it's being
          used. By using our website, registering an account, or making a
          purchase, you hereby consent to our Disclaimer and agree to its terms.
        </p>

        <h4>Changes To Our Disclaimer</h4>
        <p>
          Should we update, amend or make any changes to this document so that
          they accurately reflect our Service and policies. Unless otherwise
          required by law, those changes will be prominently posted here. Then,
          if you continue to use the Service, you will be bound by the updated
          Disclaimer. If you do not want to agree to this or any updated
          Disclaimer, you can delete your account.
        </p>
        <h4>Contact Us</h4>
        <p>
          Don't hesitate to contact us if you have any questions regarding this
          Disclaimer.
        </p>
        <ul>
          <li>
            Via Email:{' '}
            <a href="mailto:viktor@viktorsdk.com">viktor@viktorsdk.com</a>
          </li>
        </ul>
      </DisclaimerLayout>
    </>
  )
}

export default Disclaimer
